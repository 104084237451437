<template>
  <div>
    <b-modal
      ref="modalDataCriticalNotification"
      :title="$t('Notificações críticas')"
      cancel-variant="outline-secondary"
      size="lg"
      body-class=""
      :visible="false"
      :ok-only="true"
      :ok-title="$t('Fechar')"
      ok-variant="primary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @ok="closeModal"
      @cancel="closeModal"
    >
      <div v-if="Object.keys(listingsRestore).length === 0 && showNoteRestore === true">
        <b-alert
          show
          variant="danger"
        >
          <div class="alert-body">
            <span class="ml-25">{{ $t('Nenhuma angariação para restaurar') }}</span>
          </div>
        </b-alert>
      </div>
      <div v-if="Object.keys(listingsRestore).length > 0">
        <b-row
          v-for="(row, index) in listingsRestore"
          :key="index"
          class="mt-2"
        >
          <b-col md="12">
            <b-row>
              <b-col
                md="3"
                class="mt-1"
              >
                <h5>{{ $t('ZMID / Nickname') }}</h5>
                <h5
                  v-if="row.sw012s02 !== null"
                  class="text-secondary mt-1"
                >
                  {{ row.sw012s02 }}
                </h5>
                <h5
                  v-else
                  class="text-secondary"
                >
                  -----
                </h5>
              </b-col>
              <b-col
                md="3"
                class="mt-1"
              >
                <h5>{{ $t('Data de alteração') }}</h5>
                <h5 class="text-secondary">
                  {{ row.sw012s61 }}
                </h5>
              </b-col>
              <b-col
                md="2"
                class="mt-1"
              >
                <h5>{{ $t('Estado') }}</h5>
                <h5
                  v-if="row.sw012s01_temp !== null"
                  class="text-secondary"
                >
                  {{ $t('Edição') }}
                </h5>
                <h5
                  v-else
                  class="text-secondary"
                >
                  {{ $t('Registo') }}
                </h5>
              </b-col>
              <b-col
                md="2"
                class="mt-1"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-primary"
                  @click="restoreListing(row.sw012s01)"
                >
                  <span>{{ $t('Restaurar') }}</span>
                </b-button>
              </b-col>
              <b-col
                md="2"
                class="mt-1"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="deleteListing(row.sw012s01, index)"
                >
                  <span>{{ $t('Eliminar') }}</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>

    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BRow, BCol, BButton, BAlert,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import useAppConfig from '@core/app-config/useAppConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { showModalDeleteMessage } from '@core/utils/utils'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BAlert,
  },
  directives: {
    Ripple,
  },
  props: {
  },
  data() {
    return {
      showNoteRestore: false,
    }
  },
  computed: {
    ...mapGetters('dashboard', ['listingsRestore']),
    isDark() {
      return this.skin.value === 'dark'
    },
  },
  methods: {
    showModal() {
      return new Promise((resolve, reject) => {
        this.$refs.modalDataCriticalNotification.show()
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    hideModal() {
      this.$refs.modalDataCriticalNotification.hide()
    },
    async closeModal() {
      this.resolvePromise(true)
    },
    restoreListing(idListing) {
      this.$root.$emit('app::loading', true)
      const urlPrevRoute = `/listings/openDraft/${btoa(idListing)}`
      this.$router.push({ path: urlPrevRoute })
    },
    async deleteListing(idListing, key) {
      const actionModal = new Promise(resolve => {
        showModalDeleteMessage(resolve, this.$t('Pretende eliminar a angariação? Esta ação é irreversível!'))
      })

      await actionModal.then(result => {
        if (result === 'confirm') {
          this.$root.$emit('app::loading', true)
          this.$store.dispatch('dashboard/deleteDraftsUser', { sw012s01: btoa(idListing) }).then(async res => {
            this.$root.$emit('app::loading', false)
            if (Number(res.error) === 1) {
              this.showMsgErrorRequest({ message: res.msg })
            } else {
              await this.listingsRestore.splice(key, 1)

              if (Object.keys(this.listingsRestore).length === 0) {
                this.showNoteRestore = true
              }

              this.showMsgSuccessRequest({ message: res.msg })
            }
          }).catch(error => {
            this.showMsgErrorRequest(error)
          })
        }
      })
    },
    async showMsgErrorRequest(error) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: this.$t('Alerta'),
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          text: error.message,
        },
      })
    },
    async showMsgSuccessRequest(info) {
      if (info.message) {
        this.$toast.clear()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: info.message,
          },
        })
      }
    },
  },
  setup() {
    const { skin } = useAppConfig()

    return {
      skin,
    }
  },
}
</script>
